.settings-shortcuts-wrapper {
  .item-header {
    display: flex;
    .item-title {
      flex: 1;
    }
  }
}

.settings-shortcuts-list {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xl);
  &.disabled {
    opacity: 0.4;
  }
}

.settings-shortcuts-list-item-header {
  font-family: var(--text-family-default);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}

.settings-shortcuts-list-item-content {
  margin-bottom: var(--spacing-l);
}

.settings-shortcuts-list-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: var(--spacing-xs);
}

.settings-shortcuts-list-item-label {
  flex: 2;

  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.settings-shortcuts-list-item-value {
  flex: 1;

  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
