@import '../../../../styles//mixins/unselectable';

.settings-dev-options-header {
  color: var(--content-color-primary);
}
.settings-dev-options-wrapper {
  .settings-dev-options-header-help-text {
    padding: var(--spacing-zero);
    font-size: var(--text-size-s);
    color: var(--content-color-secondary);
  }

  .settings-dev-options-list {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: var(--spacing-l);

    .settings-dev-options-active-menu-item {
      display: inline-block;
    }

    .settings-dev-select-menu {
      width: 100%;
    }

    .settings-dev-submit-button {
      flex-shrink: 0;
    }
  }

  .settings-dev-options-list-item {

    .settings-dev-options-list-item-label {
      flex: 2;
      padding: var(--spacing-xs) var(--spacing-zero);
      @include unselectable;
    }

    .settings-dev-options-list-item-help-text {
      padding: var(--spacing-zero);
      font-size: var(--text-size-s);
      color: var(--content-color-secondary);
    }

    .settings-dev-options-list-item-value {
      padding-left: var(--spacing-l);
      display: flex;
      justify-content: flex-start;
      align-self: flex-start;
      flex-direction: column;
    }
  }

  .dropdown {
    width: 100%;
    height: 30px;

    .dropdown-button {
      display: flex;

      .btn {
        flex: 1;
        height: 30px;
        justify-content: space-between;
      }
    }
  }
}

.settings-dev-select-dropdown-menu .dropdown-menu-item {
  min-height: 32px;
  height: max-content;
  padding: var(--spacing-xs) var(--spacing-l);
  margin-bottom: var(--spacing-xs);
}

// To override base dropdown styles to match Aether dropdown
.settings-dev-select-menu {
  width: 100%;

  .input-search-group .input {
    font-weight: var(--text-weight-regular);
  }
}
