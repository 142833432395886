@import '../../../../styles/mixins/unselectable';

.settings-autoupdate {
  display: flex;
  flex-direction: row;
  padding-bottom: var(--spacing-s);
  align-items: center;
  margin-bottom: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .settings-autoupdate-info-container {
    flex: 1;
    padding: var(--spacing-s) var(--spacing-zero);

    .settings-autoupdate-header {
      font-weight: var(--text-weight-medium);
      padding-bottom: var(--spacing-s);
    }
    .settings-autoupdate-body {
      margin-bottom: var(--spacing-s);
    }
    .settings-autoupdate-footer {
      color: var(--content-color-tertiary);
    }
  }

  .settings-autoupdate-label {
    @include unselectable;
  }
}

.settings-autoupdate-value-dropdown {
  .dropdown-menu {
    min-width: 120px;
  }
}

.settings-update-changelog-container {
  max-height: 360px;
  overflow-y: auto;
}

.update-error__message {
  padding: var(--spacing-m) var(--spacing-zero);
}

.settings__update__container {
  display: flex;
  padding: var(--spacing-s) var(--spacing-zero);

  .settings__update__info-container {
    flex: 1;

    .settings__update__header {
      font-weight: var(--text-weight-medium);
      padding-bottom: var(--spacing-s);
    }
  }

  .settings__update__action-container {
    flex: 0 0 400px;
    margin-left: auto;
  }
}
