.settings-data-header-description {
  padding: var(--spacing-xs) var(--spacing-zero);
  color: var(--content-color-secondary);
}

.settings-data-header-description-note {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.settings-data-section-separator {
  padding: var(--spacing-m) var(--spacing-zero);
}

.settings-data-option-wrapper {
  padding: var(--spacing-xs) var(--spacing-zero);
}

.settings-data-option {
  display: block;
  padding-bottom: var(--spacing-xs);
  .radio-button {
    margin-right: var(--spacing-xs);
  }
}

.settings-data-export-button-wrapper {
  padding-top: var(--spacing-m);
}

.settings-data-export-button {
  display: flex;
  flex-shrink: 0;
  height: var(--size-m);
}

.settings-upload-button, // To override base upload button styles to make it looks consistent with the Aether outline button
.settings-data-import-button {
  background-color: var(--button-outline-background-color);
  border: var(--border-width-default) var(--border-style-solid) var(--button-outline-border-color);
  color: var(--content-color-primary);
  display: flex;
  flex-shrink: 0;
  font-weight: var(--text-weight-regular);
  height: var(--size-m);
  box-shadow: none;

  &:hover {
    background-color: var(--button-outline-hover-background-color);
    border-color: var(--button-outline-hover-border-color);
  }
}

.settings-data-warning {
  background: var(--background-color-tertiary);
  padding: var(--spacing-xs);
  padding-left: var(--spacing-l);
  margin: var(--spacing-s) var(--spacing-zero);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__workspaces {
    color: var(--content-color-primary);
    cursor: pointer;
  }

  &__list {
    margin-left: 0;
    padding-left: var(--spacing-xl);
    max-width: 400px;
  }

  &__content {
    display: flex;
    align-items: center;

    .pm-icon {
      margin-right: var(--spacing-s);
    }
  }
}
