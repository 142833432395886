@mixin icon($url, $width, $height, $container_width:'', $container_height:'') {
  display: block;
  width: $width;
  height: $height;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url($url);

  @if $container_width != '' {
    margin-left: ($container_width - $width)*0.5;
  }

  @if $container_height != '' {
    margin-top: ($container_height - $height)*0.5;
  }
}

// Sets the size of an icon
@mixin icon-size($size) {
  height: $size;
  width: $size;

  svg {
    height: $size;
    width: $size;
  }
}

// adds color to the icon
@mixin icon-color ($color) {
  use {
    fill: $color;
  }
  // We have introduced new icons from the design-system package which have path tags in their svgs
  // Since the existing icons also have some path tags, we are checking for only those paths which have a fill property
  svg:not([class*="PartnerIcon"]) > path[fill] {
    fill: $color;
  }

  svg {
    color: $color;
  }
}
