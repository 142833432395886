@import '../../../../styles/mixins/selectable';

$postman-logo-url: '../../../../assets/images/thumbnails/postman-logo.svg';

.settings-about-wrapper {
  position: relative;

  .settings-about-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-xxxl);
  }

  .postman-logo {
    height: 84px;
    width: 84px;
    flex: 0 0 84px;
    background-image: url($postman-logo-url);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .settings-about-version {
    display: flex;
    margin-top: var(--spacing-xs);
    justify-content: center;
    @include selectable;
  }

  .settings-about-platform {
    display: flex;
    margin-top: var(--spacing-xs);
    justify-content: center;
    @include selectable;
  }

  .settings-about-architecture {
    display: flex;
    margin-top: var(--spacing-xs);
    justify-content: center;
    @include selectable;
  }

  // Reduce links emphasis
  .settings-about-links {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-xs);

    i > svg {
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover i > svg {
      opacity: 1;
    }
  }
}
