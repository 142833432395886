.settings-addons-link-wrapper {
  padding-bottom: var(--spacing-s);
  
  .settings-addons-link {
    color: var(--content-color-brand);
    text-decoration: none;
  }
}

.settings-addons-description {
  font-size: var(--text-size-s);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
