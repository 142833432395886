@import '../../../../styles/mixins/clickable';

.settings-themes-list {
  display: flex;
  justify-content: space-around;
}

.settings-themes-description {
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-xl) var(--spacing-zero);
  display: flex;
  justify-content: space-around;
}

.settings-theme-thumbnail {
  flex: 0 0 260px;
  height: 165px;
  background-size: contain;
  border: var(--border-width-l) var(--border-style-solid) transparent;
  border-radius: var(--border-radius-default);
  background-repeat: no-repeat;
  background-position: 50%;

  @include clickable;

  &.active {
    border-color: var(--highlight-background-color-brand);
  }

}
