@import '../../../../styles/mixins/unselectable';
@import '../../../../styles/mixins/icon';

.settings-general-wrapper {

  .settings-general-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: var(--spacing-zero) var(--spacing-s);
  }

  .settings-general-list-separator {
    flex: 0 0 var(--size-xs);
  }

  .settings-general-list-group {
    font-weight: var(--text-weight-medium);
    @include unselectable;
  }

  .settings-general-editor-settings-heading {
    display: inline-flex;
    align-items: center;
  }

  .settings-general-list-item {

    &.is-disabled {
      cursor: default;
      opacity: 0.5;
    }

    .settings-general-list-item-label {
      flex: 2;
      padding: var(--spacing-xs) var(--spacing-zero);
      @include unselectable;
    }

    .settings-general-list-item-help-text {
      padding: var(--spacing-xs) var(--spacing-zero);
      font-size: var(--text-size-s);
      color: var(--content-color-tertiary);

      &.request-validation-description {
        padding-right: var(--spacing-xxl);
      }
    }

    .settings-general-list-item-value {
      flex: 0 0 70px;
      padding-left: var(--spacing-l);
      display: flex;
      justify-content: flex-start;
      height: var(--size-m);
      align-self: flex-start;

      &.editor-indent-tab {
        padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
        height: 100%;
      }

      &.documentation-default-editor {
        flex: 0 0 auto;
      }
    }

    .settings-general-list-item--working-dir_help-link{
      padding-top: var(--spacing-xs);
      font-size: var(--text-size-m);
    }
  }

  .settings-general-list-item--working-dir {
    flex-wrap: wrap;

    .settings-general-list-item-label {
      padding-bottom: var(--spacing-zero);
    }

    .settings-general-list-item--working-dir_value {
      display: flex;
      flex-basis: 100%;
      padding: var(--spacing-xs) va(--spacing-zero);

      .settings-general-list-item--working-dir_file-input {
        display: flex;
        flex: 1;
        margin-right: var(--spacing-l);
      }

      .settings-general-list-item--working-dir_file-button {
        display: flex;
        flex: 0 0 70px;
      }
    }

    .settings-general-list-item-label--working-dir_label {
      display: flex;
      align-items: center;

      .warning-button {
        margin-left: var(--spacing-xs);
      }
    }
  }

  .settings-general-list-item--working-dir_help-text {
      padding: var(--spacing-xs) var(--spacing-zero);
      font-size: var(--text-size-s);
      color: var(--content-color-tertiary);
  }

  .settings-general-list-item--font-family {
    flex-wrap: wrap;

    .settings-general-list-item-label {
      padding-bottom: var(--spacing-zero);
    }

    .settings-general-list-item--font-family_value {
      display: flex;
      flex-basis: 100%;
      padding: var(--spacing-xs) var(--spacing-zero);

      .settings-general-list-item--font-family-input{
        display: flex;
        flex: 1;
      }
    }

    .settings-general-list-item-label--font-family_label {
      display: flex;
      flex-direction: column;
    }
  }

  .settings-general-list-item--font-family_help-text {
    padding: var(--spacing-xs) var(--spacing-zero);
    font-size: var(--text-size-s);
    color: var(--content-color-tertiary);
}

  .settings-general-list-item--insecure-fileread {
    .warning-icon {
      @include icon-color(var(--base-color-warning));
      position: relative;
      top: 1px;
      margin-right: var(--spacing-xs);
    }
  }

  .dropdown {
    width: 100%;
    height: var(--controls-size-default);
    background-color: var(--background-color-tertiary);

    .dropdown-button {
      display: flex;

      .btn {
        flex: 1;
        height: var(--controls-size-default);
        justify-content: space-between;
      }
    }
  }
}
